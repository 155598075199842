import React from "react"
import Layout from "../components/Layouts"
import SEO from "../components/SEO"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

const BottomSpacer = styled.div`
  margin-bottom: 400px;
  @media (max-width: 420px) {
    margin-bottom: 500px;
  }
  @media (max-width: 380px) {
    margin-bottom: 600px;
  }
`
const Wrapper = styled.div`
  width: 88%;
  margin: 0 auto;
`
const Title = styled.h1`
  text-align: center;
  padding: 20px;
 
`
const SubTitle = styled.h4`
  padding: 20px;
  text-decoration: underline;
  margin-left: 20px;
`
const SubText = styled.h6`
  text-align: center;
  font-style: italic;
`;
const LessonTitle = styled.h5`
  padding: 20px 0 5px;
  text-align: center;
`
const AudioGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 900px;
  margin: 20px auto;
  padding-top: 20px;
  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`
const VideoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: auto;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`
const SNOLink = styled.div`
  text-align: center;;
  margin: auto;
  margin-top: 40px;
  width: 200px;
`

const MediaItem = styled.div`
  margin: auto;
`
const Media = () => {
  // NOT USING THIS QUERY BUT WITHOUT QUERY IT FAILS BECAUSE OF SEO??
  const { allSanitySchedule } = useStaticQuery(graphql`
    query {
      allSanitySchedule {
        nodes {
          title
          startDate(formatString: "dddd MMMM Do, YYYY")
          author
          description
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Colorado Media" description="Media for The School of the Natural Order"/>
      <Wrapper>
        <Title>Media Page</Title>

        <SubTitle> Dr. John Gozzi - May 1981</SubTitle>
        <LessonTitle>
          Lessons 16 thru 25 from "The Natural Order Process Volume 1
        </LessonTitle>
        <SubText>" These lessons are descriptions of "The Science of Semantics"</SubText>

        <AudioGrid>
          <MediaItem>
            <h6>Part 1 Vol. 1, Lesson 16.mp3</h6>
            <audio controls>
              <source
                type="audio/mpeg"
                src="https://dl.dropbox.com/s/g3pvgq52b95i3n9/1_John%20Gozzi%20Vol1%20lesson%2016%20.mp3?"
              ></source>
            </audio>
          </MediaItem>
          <MediaItem>
            <h6>Part 2 Vol. 1, Lesson 17.mp3</h6>
            <audio controls>
              <source
                type="audio/mpeg"
                src="https://dl.dropbox.com/s/apwc32tc0e8ttsd/2_Gozzi%20Vol.%201%2C%20Lesson%2017%20%205-1-1981.mp3?"
              ></source>
            </audio>
          </MediaItem>
          <MediaItem>
            <h6>Part 3 Vol. 1, Lesson 18.mp3</h6>
            <audio controls>
              <source
                type="audio/mpeg"
                src="https://dl.dropbox.com/s/ekn63ltfox7awu0/3_John%20Gozzi%20Vol.%201%20Lesson%2018%20May%201981.mp3?"
              ></source>
            </audio>
          </MediaItem>
          <MediaItem>
            <h6>Part 4 Vol. 1, Lesson 20.mp3</h6>
            <audio controls>
              <source
                type="audio/mpeg"
                src="https://dl.dropbox.com/s/7c2wg23dgia4d23/4_Gozzi%20Volume%201%20Lesson%2020%2C%205-22-1981.mp3?"
              ></source>
            </audio>
          </MediaItem>
          <MediaItem>
            <h6>Part 5 Vol. 1, Lesson 22 & 23.mp3</h6>
            <audio controls>
              <source
                type="audio/mpeg"
                src="https://dl.dropbox.com/s/5q889cgt10ufp09/5_Gozzi%20Volume%201%20lesson%2022%20%26%2023%2C%206-5-1981.mp3?"
              ></source>
            </audio>
          </MediaItem>
          <MediaItem>
            <h6>Part 6 Vol. 1, Lesson 24 & 25.mp3</h6>
            <audio controls>
              <source
                type="audio/mpeg"
                src="https://dl.dropbox.com/s/bu8by1n6h23kstd/6_Gozzi%20Volume%201%20Lesson%2024%20%26%2025%2C%206-12-1981%20.mp3?"
              ></source>
            </audio>
          </MediaItem>
        </AudioGrid>
        <SubTitle> Dr. John Gozzi</SubTitle>
        <LessonTitle> 2 Part video series:</LessonTitle>
        <SubText>"Daily Use of the SNO Teachings"</SubText>
        <VideoGrid>
          <MediaItem>
            <LessonTitle>Part 1</LessonTitle>
            <video width="400" height="300" controls>
              <source
                type="video/mp4"
                src="https://www.dropbox.com/s/6wacmaarzj2afyq/Daily_use_1.m4v?raw=1"
              ></source>
            </video>
          </MediaItem>
          <MediaItem>
            <LessonTitle>Part 2</LessonTitle>
            <video width="400" height="300" controls>
              <source
                type="video/mp4"
                src="https://www.dropbox.com/s/h5moum5m2yd1xbp/Daily_use_2.m4v?raw=1"
              ></source>
            </video>
          </MediaItem>
        </VideoGrid>
        <SNOLink>
          <a href="https://www.sno.org/books-and-mp3s" style={{color: "#ca0388"}}>
            <h5>Please click here for the
            SNO Nevada media page

            sno.org</h5>
          </a>
        </SNOLink>
      </Wrapper>
      <BottomSpacer />
    </Layout>
  )
}
export default Media
